<template>
	<div v-if="!appLoading">
		<r-share-dialog v-if="memory.is_owner" :memory="memory" :existing_shared_list="memory.user_shared_memories_attributes"></r-share-dialog>
		<v-app-bar fixed color="background" elevation="0" class="ios-bar py-lg-2 pr-lg-3">
			<router-link class="d-flex align-center" :to="{name: 'Home'}">
				<v-img alt="Rewind Logo" class="shrink mr-2" contain src="../../assets/logo_small.png" transition="scale-transition" width="40"/>
			</router-link>
			<v-btn icon rounded exact @click="goBack">
				<v-icon>mdi-arrow-left</v-icon>
			</v-btn>
			<v-spacer></v-spacer>
			<v-btn v-if="memory.is_owner" :to="{name:'EditMemory', params:{id: id}}" color="accent" rounded>edit</v-btn>
			<v-menu bottom right>
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-bind="attrs" v-on="on" class="ml-2">
						<v-icon>mdi-dots-vertical</v-icon>
					</v-btn>
				</template>

				<v-list color="background">
					<v-dialog v-model="forgetDialog" width="600">
						<template v-slot:activator="{ on, attrs }">
							<v-list-item active-class="no-active" v-bind="attrs" v-on="on">
								<v-list-item-icon>
									<v-icon>mdi-delete</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title>forget memory</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</template>
						<v-card color="background">
							<v-card-title class="text-h6 error py-2">forget memory</v-card-title>
							<v-card-text class="mt-5">This will permanently remove this memory. Are you sure?</v-card-text>
							<v-divider></v-divider>
							<v-card-actions>
								<v-btn color="white" text @click="forgetDialog = false">keep</v-btn>
								<v-spacer></v-spacer>
								<v-btn color="error" rounded @click="forgetMemory">forget</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-list>
			</v-menu>
		</v-app-bar>
		<v-container>
			<v-row>
				<v-col cols="12" class="mx-auto">
					<v-row>
						<v-col cols="12" lg="8" class="mx-auto">
							<h3 class="text-h4 text-center">{{ memory.title }}</h3>
						</v-col>
						<v-col cols="12" lg="8" class="mx-auto" v-if="memory.owner">
							<router-link :to="{name:'Shared', params:{friend_id: memory.owner.id}}">
								<h6 class="accent--text text-center">
									<v-icon small color="accent">mdi-share</v-icon>
									by {{ memory.owner.name }}
								</h6>
							</router-link>
						</v-col>
						<v-col cols="12" lg="8" class="mx-auto">
							<p class="text-center">{{ prettyDate }}</p>
						</v-col>
						<v-col cols="12" class="mx-auto" v-if="memory.topics.length > 0">
							<v-chip-group column class="justify-center">
								<v-chip v-for="topic in memory.topics" outlined color="accent">
									{{ topic.name }}
								</v-chip>
							</v-chip-group>
						</v-col>
						<v-col cols="12" class="mx-auto" v-if="memory.user_shared_memories_attributes.length > 0">
							<v-row justify="center">
								<v-col cols="auto" v-for="shared_user in memory.user_shared_memories_attributes">
									<v-tooltip bottom color="accent">
										<template v-slot:activator="{ on, attrs }">
											<v-avatar class="profile ma-5" size="50" v-bind="attrs" v-on="on">
												<v-img :src="imageSrc(shared_user.avatar, true)"></v-img>
											</v-avatar>
										</template>
										<span>shared with {{ shared_user.name }}</span>
									</v-tooltip>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="12" lg="8" class="mx-auto">
							<div style="white-space: pre-wrap;">{{ memory.description }}</div>
						</v-col>
					</v-row>
					<v-row class="mt-5">
						<v-col cols="12" sm="6" md="6" lg="4" v-if="memory.featured_image.url" @click="showLightbox(memory.featured_image)">
							<r-card-image :url="memory.featured_image.url" :thumb_url="memory.featured_image.thumb_url" show/>
						</v-col>
						<v-col v-for="attachment in memory.attachments_attributes" @click="showLightbox(attachment)" v-if="attachment.type === 'image'" cols="12" sm="6" md="6" lg="4">
							<r-card-image :url="attachment.url" :thumb_url="attachment.thumb_url" show/>
						</v-col>
						<v-col v-for="attachment in memory.attachments_attributes" v-if="attachment.type === 'video'" cols="12" sm="6" md="6" lg="4">
							<r-card-video :url="attachment.url"/>
						</v-col>
						<v-col v-for="attachment in memory.attachments_attributes" v-if="attachment.type === 'audio'" cols="12" sm="6" md="6" lg="4">
							<r-card-audio :url="attachment.url"/>
						</v-col>
						<v-col v-for="attachment in memory.attachments_attributes" v-if="attachment.type === 'doc'" cols="12" sm="6" md="6" lg="4">
							<r-card-doc :url="attachment.url" :filename="attachment.meta.filename"/>
						</v-col>
						<v-col v-for="url in memory.urls" cols="12" sm="6" md="6" lg="4">
							<r-card-url :url="url"/>
						</v-col>
						<v-col v-for="attachment in memory.attachments_attributes" v-if="attachment.type === 'unknown'" cols="12" sm="6" md="6" lg="4">
							<r-card-unknown :url="attachment.url"/>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			<v-overlay color="background" opacity="0.9" :value="lightbox.show">
				<v-btn small style="position: fixed; bottom:0; right:0" fab color="accent" class="ma-5" @click="lightbox.show = false">
					<v-icon>mdi-close</v-icon>
				</v-btn>
				<v-btn small style="position: fixed; bottom:0; left: 20px;" fab color="accent" class="my-5" :href="lightbox.attachment.url" target="_blank">
					<v-icon>mdi-download</v-icon>
				</v-btn>
				<div class="lightbox">
					<div class="lightbox--inner" :style="`background-image: url(${lightbox.attachment.url})`">

					</div>
				</div>
			</v-overlay>
		</v-container>
	</div>
</template>

<script>
import RShareDialog from "@/views/memory/components/ShareDialog";
import Models from "@/models";

export default {
	name: "Show",
	metaInfo() {
		return {
			title: this.memory?.title || 'Memory',
		}
	},
	props: ['id'],
	components: {
		RShareDialog
	},
	beforeMount() {
		this.getMemory()
	},
	computed: {
		prettyDate() {
			return this.memory.date ? new Date(this.memory.date).toLocaleDateString('en-GB') : new Date().toLocaleDateString('en-GB')
		}
	},
	data: () => ({
		memory: Models.Memory(),
		imageIndex: null,
		forgetDialog: false,
		lightbox: {
			show: false,
			attachment: {
				id: null,
				url: ''
			}
		}
	}),
	methods: {
		showLightbox(attachment) {
			this.lightbox.show = true;
			this.lightbox.attachment = attachment;
		},
		downloadAttachment() {
			this.$api.downloads.attachment(this.lightbox.attachment.id)
				.then(response => console.log(response))
				.catch(error => this.$toast.add('Sorry there was a problem', 'error'))
		},
		getMemory() {
			this.appLoading = true
			this.$api.memories.show(this.id)
				.then(response => this.memory = response.data)
				.catch(error => console.log(error))
				.finally(() => this.appLoading = false)
		},
		goBack() {
			this.$router.push({name: 'Year', params: {year: this.memory.year}})
		},
		forgetMemory() {
			this.$api.memories.destroy(this.id)
				.then(response => this.$router.push({name: 'Home'}))
				.catch(error => console.log(error))
		}
	}
}
</script>


<style scoped lang="scss">
.download-btn {
	position: fixed;
	bottom: 0;
	left: 0;
	transform: translateX(calc(50vw - 60px))
}

.lightbox {
	height: 100vh;
	width: 100vw;

	.lightbox--inner {

		/* The image used */


		/* Full height */
		height: 100%;

		/* Center and scale the image nicely */
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
	}
}
</style>